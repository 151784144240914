<template>
  <div class="page adjust_width">
    
    <div v-if="alert_stock_limit.length">
      
      <title_cat>Prochainement en rupture</title_cat>
  
      <produit_list v-for="n in alert_stock_limit" :key="n.id" :data="n" type="stock" @refresh="rupture_get"></produit_list>
  
<!--      <p v-if="!alert_stock_limit.length" class="info_ico"><i class="el-icon-circle-check"></i> Aucune alerte</p>-->

    </div>
    
    
      <title_cat class="title" icon="circle-plus-outline" :clickable="true" :link="{ name: 'bo_categorie_add' }">Mes catégories</title_cat>
<!--    <router-link tag="div" :to="{ name: 'bo_produit_add' }" class="categorie plus">Ajouter un article</router-link>-->
    
<!--    <router-link tag="div" :to="{ name: 'bo_categorie_add' }" class="categorie plus shadow">-->
<!--      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M13,7H11V11H7V13H11V17H13V13H17V11H13V7Z" /></svg>-->
<!--      Ajouter une catégorie-->
<!--    </router-link>-->
    <div v-if="boutique.boutique">
      <div class="categorie shadow" v-for="(n, k) in boutique.boutique.categories" :key="n">
        <router-link tag="div" :to="{ name: 'bo_produits_in_category', params: { categorie: n } }" class="left">
          <div class="name">{{ n }}</div>
          <div class="nb">{{ nb_by_categories[n] }} article<span v-if="nb_by_categories[n] > 1">s</span></div>
        </router-link>
        <div class="right">
          <div class="up" @click="up(k)"><i class="el-icon-caret-top"></i></div>
          <div class="up" @click="down(k)"><i class="el-icon-caret-bottom"></i></div>
          <router-link :to="{ name: 'bo_produits_in_category', params: { categorie: n } }" tag="span" class="edit_ico">
            <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" /></svg>
          </router-link>
        </div>
      </div>
    </div>
      
    <p v-if="!boutique.boutique || !boutique.boutique.categories" class="info_ico"><i class="el-icon-document-delete"></i> Pas encore de catégories.</p>

    
    
    
    

    <title_cat class="title" icon="circle-plus-outline" :clickable="true" :link="{ name: 'bo_options_add' }">Suppléments / Options</title_cat>
    <div v-if="boutique.boutique">
      <router-link tag="div" :to="{ name: 'bo_options_edit', params: { option_id: n.id } }" class="categorie shadow" v-for="n in options" :key="n.id">
<!--        <img class="ico" :src="'/img/ico/food/' + n.type + '.png'" alt="">-->
        <div class="left">
          <div class="name">{{ n.name }}</div>
          <div class="nb capitalize">{{ n.type }}</div>
        </div>
        <div class="right">
          <svg class="edit_ico" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" /></svg>
        </div>
      </router-link>
    </div>
    
    <p v-if="!Object.keys(options).length" class="info_ico"><i class="el-icon-document-delete"></i> Pas encore d'options.</p>
    
    
  </div>
</template>

<script>
import produit_list from "@/components/bo/produit_list";
export default {
  name: "produits",
  components: { produit_list },
  data() {
    return {
      boutique: {},
      options: {},
      
      nb_by_categories: {},

      alert_stock_limit: [],
    }
  },
  created() {
    this.$http.get('/bo/boutique/').then((response) => {
      this.boutique = response.data
      console.log(response)
      if(this.boutique.boutique) {
        for(let n in this.boutique.boutique.categories) {
          this.nb_by_categories[this.boutique.boutique.categories[n]] = 0
        }
      }

      if(this.boutique.produits) {
        for (let n in this.boutique.produits) this.nb_by_categories[this.boutique.produits[n]['categorie']]++
      }
    })
    this.rupture_get()
    this.options_list()
  },
  methods: {
    rupture_get() {
      console.log('rupture_get')
      this.$http.get('/bo/boutique/alert_stock_limit').then((response) => {
        this.alert_stock_limit = response.data
      })
    },
    options_list() {
      this.$http.get('/bo/boutique/options').then((response) => {
        this.options = response.data
      })
    },

    up(i) {
      this.boutique.boutique.categories = this.array_move(this.boutique.boutique.categories, i, i - 1)
      this.$forceUpdate()
      this.save_categories()
    },
    down(i) {
      if(this.boutique.boutique.categories.length > i + 1) {
        this.boutique.boutique.categories = this.array_move(this.boutique.boutique.categories, i, i + 1)
        this.$forceUpdate()
        this.save_categories()
      }
    },
    save_categories() {
      this.$http.post('/bo/boutique/edit', {
        id: this.$store.state.user.user.boutique,
        key: 'categories',
        value: this.boutique.boutique.categories
      }).catch(e => console.log(e))
    },
    array_move(arr, old_index, new_index) {
      console.log(arr, old_index, new_index)
      while (old_index < 0) {
        old_index += arr.length;
      }
      while (new_index < 0) {
        new_index += arr.length;
      }
      if (new_index >= arr.length) {
        let k = new_index - arr.length + 1;
        while (k--) {
          arr.push(undefined);
        }
      }
      arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
      return arr; // for testing purposes
    }
    // nb_produits(categorie) {
    // }
  }
}
</script>

<style scoped>

.title { margin-top: 20px; }
.title:nth-child(1) { margin-top: 0; }

.categorie { background: #fff; display: flex; height: 60px; align-items: center; justify-content: space-between; font-size: 16px; padding: 0 14px; margin-bottom: 3px; border-radius: 2px; 
  transition: all .3s ease; }
.categorie .left { cursor: pointer; width: 100%; height: 100%; display: flex; justify-content: center; flex-direction: column; }
.categorie .name { font-weight: bold; }
.categorie .nb { font-size: 12px; color: var(--boColor-gris2) }
.categorie .right svg { fill: var(--boColor-gris1) }
.categorie.plus { display: flex; align-items: center; justify-content: center; height: 50px; margin-bottom: 4px; }
.categorie.plus svg { fill: var(--boColor-gris2); margin-right: 6px; }

.categorie.ico { position: relative; padding-left: 66px; }
.categorie .ico { position: absolute; left: 6px; top: 50%; width: 48px; transform: translateY(-50%) }
.categorie .edit_ico { cursor: pointer; margin-left: 6px; }

.categorie .right { height: 70%; display: flex; align-items: center }
.categorie .right .up { display: inline-flex; align-items: center; justify-content: center; border-radius: 16px; color: var(--black-sub); height: 20px; width: 20px; background: #f5f5f5;
  margin-right: 12px; cursor: pointer }


.categorie:hover { box-shadow: 0 0 1px 0 rgba(#fcf7f7,1)!important; background-color: #fef5f5 }


.article { display: flex; min-height: 50px; align-items: center; padding: 0 16px 0 0; background: #fff; font-size: 16px; border-bottom: 1px solid #eee; min-height: 50px;  }
.article .nb { width: 30px; }
.article .name { width: calc(100% - 160px); display: flex; align-items: center; }
.article .name { padding-left: 6px; font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.article .img { width: 60px; min-width: 60px; height: 60px; padding: 2px; background: #fff; margin-right: 8px; border-radius: 2px; overflow: hidden }
.article .img .el-image { border-radius: 2px; overflow: hidden }
.article .price { width: 100px; text-align: right; padding-right: 20px; display: flex; flex-direction: column }
.article .price .p { line-height: 20px; }
.article .price .r { font-weight: 400; line-height: 6px;  font-size: 11px; margin-top: 4px; color: #C92C2C }
.article .price .r .old { text-decoration: line-through; color: #898a8b }
.article .edit { width: 30px; display: flex; align-items: center; justify-content: flex-end; }



@media only screen and (min-width: 800px) {
  .categorie.plus { display: inline-flex; margin-bottom: 8px; }
}

</style>
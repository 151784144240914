<template>
  
  
  <div class="article shadow" v-if="data && data.name" :class="{ not_active: data.active !== true }">
    <router-link tag="div" :to="{ name: 'produit', params: { product_id: data.id, name: data.name.replaceAll(' ', '_'), view: true } }" class="img">
      <el-image :src="$img_path + data.img_key" fit="contain"></el-image>
    </router-link>
    <router-link tag="div" :to="{ name: 'bo_produit_edit', params: { product_id: data.id } }" class="name cursor">
      <div class="n">{{ data.name }}</div>
      <div class="s">{{ data.final_price | formatNumberdecimal }}€ <span v-if="data.reduc > 0"> • {{ data.price }}€ -{{ data.reduc }}%</span></div>
<!--      <div class="s" v-if="type !== 'stock' && data.stock_suivi === true">{{ data.stock }} restant<span v-if="data.stock !== 1">s</span></div>-->
    </router-link>
<!--    <router-link tag="div" :to="{ name: 'bo_produit_edit', params: { product_id: data.id } }" class="price cursor" v-if="!type">-->
<!--      <div class="p">{{ data.final_price | formatNumberdecimal }}€</div>-->
<!--      <div class="r" v-if="data.reduc > 0"><span class="old">{{ data.price }}€</span> -{{ data.reduc }}%</div>-->
<!--    </router-link>-->
<!--    <router-link tag="div" :to="{ name: 'bo_produit_edit', params: { product_id: data.id } }" class="price cursor" v-else-if="type === 'stock'">-->
<!--      <div class="p">{{ data.stock }} restant<span v-if="data.stock !== 1">s</span></div>-->
<!--    </router-link>-->
    <div class="edit">

      <div class="up" @click="$emit('up')"><i class="el-icon-caret-top"></i></div>
      <div class="up" @click="$emit('down')"><i class="el-icon-caret-bottom"></i></div>

      <el-dropdown trigger="click" @command="handleCommand">
      <div>
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" width="24" height="24" viewBox="0 0 24 24"><path d="M14.06,9L15,9.94L5.92,19H5V18.08L14.06,9M17.66,3C17.41,3 17.15,3.1 16.96,3.29L15.13,5.12L18.88,8.87L20.71,7.04C21.1,6.65 21.1,6 20.71,5.63L18.37,3.29C18.17,3.09 17.92,3 17.66,3M14.06,6.19L3,17.25V21H6.75L17.81,9.94L14.06,6.19Z" /></svg>
      </div>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="edit" icon="el-icon-edit">Editer</el-dropdown-item>
<!--          <el-dropdown-item divided command="add_stock" icon="el-icon-plus">Ajouter un stock</el-dropdown-item>-->
<!--          <el-dropdown-item command="remove_stock" icon="el-icon-minus">Retirer un stock</el-dropdown-item>-->
          <el-dropdown-item divided command="duplicate_product" icon="el-icon-document-copy">Dupliquer</el-dropdown-item>
          <el-dropdown-item divided command="disable_product" icon="el-icon-error" v-if="data.active">Retirer de la vente</el-dropdown-item>
          <el-dropdown-item divided command="enable_product" icon="el-icon-success" v-if="!data.active">Mettre en vente</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>


    <transition name="slide-fade">
    <input_solo label="Inscrivez le nombre de produits à ajouter au stock" type="number" @result="stock_add" @close="stock_add_input_etat = false" v-if="stock_add_input_etat"></input_solo>
    </transition>

    <transition name="slide-fade">
    <input_solo label="Inscrivez le nombre de produits à retirer du stock" type="number" @result="stock_remove" @close="stock_remove_input_etat = false" v-if="stock_remove_input_etat"></input_solo>
    </transition>
      
  </div>
</template>

<script>
import Vuex from "vuex";

export default {
  name: "produit_list",
  data() {
    return {
      dialogFormVisible: false,
      
      stock_add_input_etat: false,
      stock_remove_input_etat: false,
    }
  },
  computed: {
    ...Vuex.mapState({
      user: state => state.user.user,
    })
  },
  props: [ 'data', 'type' ],
  methods: {
    stock_add(nb) {
      this.stock_add_input_etat = false
      this.$http.post('/bo/produit/change_stock', {boutique_id: this.user.boutique, product_id: this.data.id, nb: nb, type: 'add'}).then((r) => {
        if (r.status === 200) {
          this.$notify({ title: 'Stock ajouté', offset: this.$notifyOffest, type: 'success' });
          this.$emit('refresh')
          this.$store.dispatch("boutique_bo/sync");
        }
      })
    },
    stock_remove(nb) {
      this.stock_remove_input_etat = false
      if(this.data.stock - nb < 0) {
        this.$notify({ title: 'Le stock du produit ne peut pas être négatif', offset: this.$notifyOffest, type: 'error' });
        return
      }
      
      this.$http.post('/bo/produit/change_stock', {boutique_id: this.user.boutique, product_id: this.data.id, nb: nb, type: 'remove'}).then((r) => {
        if (r.status === 200) {
          this.$notify({ title: 'Stock retiré', offset: this.$notifyOffest, type: 'success' });
          this.$emit('refresh')
          this.$store.dispatch("boutique_bo/sync");
        }
      })
    },
    handleCommand(command) {
      if(command === 'edit')
        this.$router.push({ name: 'bo_produit_edit', params: { product_id: this.data.id  } })
      if(command === 'add_stock')
        this.stock_add_input_etat = true
      if(command === 'remove_stock')
        this.stock_remove_input_etat = true
      if(command === 'duplicate_product') {
        this.$http.post('/bo/produit/duplicate', { boutique_id: this.user.boutique, product_id: this.data.id }).then((r) => {
          if (r.status === 200) 
            this.$emit('refresh')
        })
      }
      if(command === 'enable_product') {
        this.$http.put('/bo/produit/active', { product_id: this.data.id, type: 'enable' }).then((r) => {
          if (r.status === 200) {
            if(!this.data.img_key) {
              this.$notify({ title: 'Erreur', message: 'Vous devez ajouter une image pour mettre votre produit en vente.', offset: this.$notifyOffest, type: 'error' });
            }
            this.$emit('refresh')
          }
        })
      }
      if(command === 'disable_product') {
        this.$http.put('/bo/produit/active', { product_id: this.data.id, type: 'disable' }).then((r) => {
          if (r.status === 200) {
            // this.$notify({ title: 'Article retiré de la vente', offset: this.$notifyOffest, type: 'success' });
            this.$emit('refresh')
          }
        })
      }
    }
  }
}
</script>

<style scoped>
  .article { display: flex; min-height: 50px; align-items: center; padding: 0!important; background: #fff; font-size: 16px; margin-bottom: 4px; border-radius: 2px; position: relative; }
  .article.not_active:after { content: ''; position: absolute; right: 2px; top: 2px; bottom: 2px; border-radius: 4px; width: 4px; background: var(--boRed) }
  .article.not_active { background-color: #fef5f5; opacity: .5; }
  .article .nb { width: 30px; }
  .article .cursor { cursor: pointer }
  .article .name { width: calc(100% - 180px); display: flex; justify-content: center; flex-direction: column; padding-left: 6px;  }
  .article .name .s { color: var(--boColor-gris2); font-weight: 400; font-size: 14px; padding-top: 2px; }
  .article .name .n { font-size: 14px; font-weight: 600; 
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
  .article .img { width: 60px; min-width: 60px; height: 60px; padding: 2px; background: #fff; margin-right: 8px; cursor: pointer; }
  .article .img .el-image { border-radius: 2px; overflow: hidden }
  .article .price { width: 120px; text-align: right; padding-right: 10px; display: flex; flex-direction: column }
  .article .price .p { line-height: 20px; }
  .article .price .r { font-weight: bold; line-height: 6px;  font-size: 11px; margin-top: 4px; color: var(--boRed) }
  .article .price .r .old { text-decoration: line-through; color: var(--boColor-gris3) }
  .article .edit { width: 50px; cursor: pointer;  display: flex; align-items: center; justify-content: flex-end; width: 140px; margin: 0 14px 0 6px }

  .article .edit svg { fill: var(--boColor-gris1) }
  
  .article .up { display: inline-flex; align-items: center; justify-content: center; border-radius: 16px; color: var(--black-sub); height: 20px; width: 20px; background: #f5f5f5;
    margin-right: 12px; cursor: pointer }
  .article .up:nth-child(2) { margin-right: 16px; }


  @media only screen and (min-width: 800px) {
    .article .name { font-size: 18px }
    .article .price { font-size: 18px }
    
    .article { min-height: 70px; padding: 10px 0 10px 16px!important; transition: all .3s ease }
    .article .img { width: 70px; height: 70px; min-width: 70px; margin-right: 16px; padding: 0; }
    /*.article:hover { transform: translateY(-4px); }*/
    .article .name .n { font-size: 18px; }
    .article .edit { width: 100px; margin: 0 12px 0 16px }
    .el-dropdown { width: 100%; display: flex; align-items: center; justify-content: center }
    .el-dropdown-selfdefine { width: 100%; display: flex; justify-content: center; }
    
  }
</style>